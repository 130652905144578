export const SEATING_MODE = {
    PER_FEATURE: 'per_feature',
    PER_SEAT: 'per_seat',
}

export const BM_API_BASE_URL = process.env.BM_API_BASE_URL || process.env.NEXT_PUBLIC_BM_API_BASE_URL || 'http://0.0.0.0:8000/1.0/'
console.log(`Using BM_API_BASE_URL ${BM_API_BASE_URL}`)

export const REDUX_FETCHING_STATES = {
    IDLE: 'idle',
    PENDING: 'pending',
}

export const PRICING_SCHEMA_TYPES = {
    NON_REFUNDABLE: 'non_refundable',
    REFUNDABLE: 'refundable',
}