import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints, mode } from '@chakra-ui/theme-tools'

// const fonts = {
//   heading: "Poppins",
//   body: "Poppins",
// }


// 2. Add your color mode config
const config = {
  initialColorMode: 'light',
  useSystemColorMode: true,
}

const breakpoints = createBreakpoints({
  sm: '43em',
  md: '62em',  
  lg: '82em',
})

const theme = extendTheme({
  config,
  styles:{    
    global: (props) => ({
      body: {
        bg: mode('white', '#252525')(props),       
      },
      colors: {
        black: '#16161D',
      },
      // fonts,     
      breakpoints,
    }),
  }
})

export default theme
