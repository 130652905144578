import axios from "axios";

import {SHOPPING_CART_URL_BASE, SHOPPING_CART_URL_EXECUTE} from "./shoppingCartEndpoints";

const createShoppingCart = (items = []) => axios.post(SHOPPING_CART_URL_BASE(), {items})
const fetchShoppingCart = (cartId) => axios.get(SHOPPING_CART_URL_BASE(cartId))
const executeShoppingCart = (cartId, data) => axios.post(SHOPPING_CART_URL_EXECUTE(cartId), data)

const shoppingCartAPI = {
    createShoppingCart,
    fetchShoppingCart,
    executeShoppingCart
}

export default shoppingCartAPI