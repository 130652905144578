import {createSelector, createSlice} from '@reduxjs/toolkit';

const cartItemBySchemaId = (state, itemId) => itemId


/*
*
* Selectors
*
* */
const draftShoppingCartItemsRoot = (state) => state.items

// for slices state is already set to the required root, don't have to do state => state.cart
const selectItemByIdForSlice = createSelector(
    [draftShoppingCartItemsRoot, cartItemBySchemaId],
    (items, itemId) => items.find(({schemaId}) => schemaId === itemId)
)

export const selectItemIndexByIdForSlice = createSelector(
    [draftShoppingCartItemsRoot, cartItemBySchemaId],
    (items, itemId) => items.findIndex(({schemaId}) => schemaId === itemId)
)

export const cartItems = state => draftShoppingCartItemsRoot(state.draftShoppingCart)

export const selectItemById = createSelector(
    [cartItems, cartItemBySchemaId],
    (items, itemId) => items.find(({schemaId}) => schemaId === itemId)
)

export const selectItemIndexById = createSelector(
    [cartItems, cartItemBySchemaId],
    (items, itemId) => items.findIndex(({schemaId}) => schemaId === itemId)
)

export const cartItemsLengthSelector = createSelector(
    [cartItems],
    allCartItems => allCartItems.length
)

export const subTotalSelector = createSelector(
    [cartItems],
    allCartItems => allCartItems.map((cartItem) => cartItem.cartItemPrice * cartItem.cartItemQuantity).reduce((a, b) => a + b, 0)
)

export const isCartEmptySelector = createSelector(
    [cartItemsLengthSelector],
    cartItemsLength => cartItemsLength === 0
)

const makeCartItem = (item) => {
  const {
    categoryItemName,
    cartItemQuantity = 1,
    categoryItemPrice,
    schemaId,
    featureTerms,
    featureTermsText,
    ...rest
  } = item
  return {
    ...rest,
    categoryItemPrice,
    cartItemName: categoryItemName,
    cartItemPrice: categoryItemPrice,
    categoryItemName,
    cartItemQuantity,
    schemaId,
    schemaName: categoryItemName,
    cartItemPoliciesURL: featureTerms,
    cartItemPoliciesText: featureTermsText
  }
}

const draftShoppingCartInitialState = {items: []}

const draftShoppingCartSlice = createSlice({
  name: 'ui-only-cart',
  initialState: draftShoppingCartInitialState,
  reducers: {
    addToCart: (state, {payload}) => {
      const {schemaId} = payload
      const itemExists = selectItemByIdForSlice(state, schemaId)
      // const itemExists = state.find((item) => item.categoryItemName === action.payload.categoryItemName);
      if (itemExists) {
        itemExists.cartItemQuantity++;
      } else {
        const formattedItem = makeCartItem(payload)
        draftShoppingCartItemsRoot(state).push({...formattedItem});
      }
    },
    incrementQuantity: (state, {payload: {schemaId}}) => {
      const item = selectItemByIdForSlice(state, schemaId)
      item.cartItemQuantity++;
    },
    decrementQuantity: (state, {payload: {schemaId}}) => {
      const item = selectItemByIdForSlice(state, schemaId)
      if (!item){
        return state
      }
      if (item.cartItemQuantity === 1) {
        const index = selectItemIndexByIdForSlice(state, schemaId)
        draftShoppingCartItemsRoot(state).splice(index, 1);
      } else {
        item.cartItemQuantity--;
      }
    },
    removeFromCart: (state, {payload: {schemaId}}) => {
      const index = selectItemIndexByIdForSlice(state, schemaId)
      draftShoppingCartItemsRoot(state).splice(index, 1);
    },
    clearDraftShoppingCart: (() => draftShoppingCartInitialState)
  },
});

export const draftShoppingCartReducer = draftShoppingCartSlice.reducer;

export const draftShoppingCartActions = draftShoppingCartSlice.actions

export const {
  addToCart,
  incrementQuantity,
  decrementQuantity,
  removeFromCart,
  clearDraftShoppingCart,
} = draftShoppingCartActions;

