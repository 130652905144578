import {Box, useColorMode} from '@chakra-ui/react'
import PropTypes from "prop-types"
import * as React from "react"
// import Footer from './footer'


function Layout({children}) {
    const {colorMode} = useColorMode()
    const bgColor = {light: '#F8F8F8', dark: '#282A29'}
    const color = {light: 'black', dark: 'white'}

    return (
        <>
            {/* <Header /> */}
            <Box
                // bg={bgColor[colorMode]}
                as="main"
                // mt='5.5rem'
            >
                {children}
            </Box>
            {/* <Footer /> */}
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
   