import {createAsyncThunk, createSelector, createSlice, isAnyOf} from '@reduxjs/toolkit'

import {REDUX_FETCHING_STATES} from "../constants";
import {shoppingCartAPI} from "../services";
import {clearDraftShoppingCart, draftShoppingCartActions} from "./draftShoppingCart.slice";


const shoppingCartInitialState = {
    shoppingCartDetails: [],
    loading: REDUX_FETCHING_STATES.IDLE,
    error: null,
    isPaymentSuccessful: false,
    isDraftShoppingCartUpdated: true
}

export const shoppingCartRoot = state => state.shoppingCart
export const shoppingCartDetailsRoot = state => state.shoppingCart.shoppingCartDetails

export {shoppingCartRoot as shoppingCartState}

const createShoppingCart = createAsyncThunk('shoppingCart/createNewCart', async (items, {
    getState, rejectWithValue
}) => {
    const {loading} = shoppingCartRoot(getState())
    if (loading !== REDUX_FETCHING_STATES.PENDING) {
        return
    }
    try {
        const response = await shoppingCartAPI.createShoppingCart(items || [])
        // eslint-disable-next-line consistent-return
        return response.data
    } catch ({response}) {
        throw rejectWithValue(response.data?.non_field_errors?.[0])
    }
})

const fetchShoppingCart = createAsyncThunk('shoppingCart/fetchCart', async (shoppingCartId, {
    getState, rejectWithValue
}) => {
    const {loading} = shoppingCartRoot(getState())
    if (loading !== REDUX_FETCHING_STATES.PENDING) {
        return
    }
    try {
        const response = await shoppingCartAPI.fetchShoppingCart(shoppingCartId)
        // eslint-disable-next-line consistent-return
        return response.data
    } catch ({response}) {
        throw rejectWithValue(response.data)
    }
})

const executeShoppingCart = createAsyncThunk('shoppingCart/execute', async ({
                                                                                shoppingCartId,
                                                                                nonce,
                                                                                email,
                                                                                firstName,
                                                                                lastName,
                                                                                phoneNumber,
                                                                                ...rest
                                                                            }, {
                                                                                getState,
                                                                                dispatch,
                                                                                rejectWithValue
                                                                            }) => {
    const {loading} = shoppingCartRoot(getState())
    if (loading !== REDUX_FETCHING_STATES.PENDING) {
        return
    }
    try {
        const response = await shoppingCartAPI.executeShoppingCart(shoppingCartId, {
            payment_method_nonce: nonce,
            customer_email: email,
            customer_first_name: firstName,
            customer_last_name: lastName,
            customer_phone: phoneNumber, ...rest,
        })
        dispatch(clearDraftShoppingCart({}))
        // eslint-disable-next-line consistent-return
        return response.data
    } catch ({response}) {
        throw rejectWithValue(response.data?.non_field_errors?.[0] ?? JSON.stringify(response.data))
    }
})


const shoppingCartSlice = createSlice({
    name: 'backend-shopping-cart',
    initialState: shoppingCartInitialState,
    reducers: {},
    extraReducers: (builder) => builder
        .addMatcher(isAnyOf(createShoppingCart.pending, fetchShoppingCart.pending, executeShoppingCart.pending), (state, action) => {
            if (state.loading === REDUX_FETCHING_STATES.IDLE) {
                state.loading = REDUX_FETCHING_STATES.PENDING
            }
        })
        .addMatcher(isAnyOf(createShoppingCart.fulfilled, fetchShoppingCart.fulfilled), (state, action) => {
            if (state.loading === REDUX_FETCHING_STATES.PENDING) {
                state.loading = REDUX_FETCHING_STATES.IDLE
                state.shoppingCartDetails = action.payload
            }
            state.isDraftShoppingCartUpdated = false
        })
        .addMatcher(isAnyOf(fetchShoppingCart.rejected, executeShoppingCart.rejected), (state, {
            payload, meta
        }) => {
            if (state.loading === REDUX_FETCHING_STATES.PENDING) {
                state.loading = REDUX_FETCHING_STATES.IDLE
                if (meta?.rejectedWithValue) {
                    state.error = JSON.stringify(payload)
                }
            }
        }).addMatcher(isAnyOf(createShoppingCart.rejected), (state, {
            payload, meta
        }) => {
            if (state.loading === REDUX_FETCHING_STATES.PENDING) {
                state.loading = REDUX_FETCHING_STATES.IDLE
                if (meta?.rejectedWithValue) {
                    state.error = payload
                }
            }
        }).addMatcher(isAnyOf(executeShoppingCart.fulfilled), (state, {
            payload, meta
        }) => {
            state.isPaymentSuccessful = payload.payment_is_success
            if (state.loading === REDUX_FETCHING_STATES.PENDING) {
                state.loading = REDUX_FETCHING_STATES.IDLE
            }
        }).addMatcher(isAnyOf(
            draftShoppingCartActions.addToCart,
            draftShoppingCartActions.incrementQuantity,
            draftShoppingCartActions.decrementQuantity,
            draftShoppingCartActions.removeFromCart,
            draftShoppingCartActions.clearDraftShoppingCart
        ), (state, {
            payload, meta
        }) => {
            state.isPaymentSuccessful = false
            state.isDraftShoppingCartUpdated = true
        })
})

export const shoppingCartReducer = shoppingCartSlice.reducer

const shoppingCartIdSelector = createSelector([shoppingCartDetailsRoot], (shoppingCart) => shoppingCart.id)
const shoppingCartIsPaymentSuccessfulSelector = createSelector([shoppingCartRoot], (shoppingCartState) => shoppingCartState.isPaymentSuccessful)
const isDraftShoppingCartUpdatedSelector = createSelector([shoppingCartRoot], (shoppingCartState) => shoppingCartState.isDraftShoppingCartUpdated)

const shoppingCartItemsSelector = createSelector([shoppingCartDetailsRoot], (shoppingCart) => shoppingCart.items)

const shoppingCartTotalSelector = createSelector([shoppingCartDetailsRoot], (shoppingCart) => shoppingCart.total)

const shoppingCartClientTokenSelector = createSelector([shoppingCartDetailsRoot], (shoppingCart) => shoppingCart.client_token)

const shoppingCartSubTotalSelector = createSelector([shoppingCartDetailsRoot], (shoppingCart) => shoppingCart.sub_total)

const shoppingCartTaxesAndFeesSelector = createSelector([shoppingCartDetailsRoot], (shoppingCart) => shoppingCart.tax_fees)

const shoppingCartDiscountAmountSelector = createSelector([shoppingCartDetailsRoot], (shoppingCart) => shoppingCart.discount_amount)

export const shoppingCartSelectors = {
    shoppingCartIdSelector,
    shoppingCartItemsSelector,
    shoppingCartTotalSelector,
    shoppingCartClientTokenSelector,
    shoppingCartSubTotalSelector,
    shoppingCartTaxesAndFeesSelector,
    shoppingCartDiscountAmountSelector,
    shoppingCartIsPaymentSuccessfulSelector,
    isDraftShoppingCartUpdatedSelector,
}

export const shoppingCartActions = {
    createShoppingCart, fetchShoppingCart, executeShoppingCart,
}

// const UsersComponent = () => {
//     const { entities, loading, error } = useSelector((state) => state.users)
//     const dispatch = useDispatch()
//
//     const fetchOneUser = async (userId) => {
//         try {
//             const user = await dispatch(fetchUserById(userId)).unwrap()
//             showToast('success', `Fetched ${user.name}`)
//         } catch (error_) {
//             showToast('error', `Fetch failed: ${error_.message}`)
//         }
//     }
//
//     // render UI here
// }