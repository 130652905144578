import {configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist'
// eslint-disable-next-line scanjs-rules/identifier_sessionStorage
import sessionStorage from 'redux-persist/lib/storage/session'

import rootReducer from "./rootReducer"; // defaults to localStorage for web


const persistConfig = {
    key: 'root',
    // eslint-disable-next-line scanjs-rules/identifier_sessionStorage
    storage: sessionStorage,
    whitelist: ['draftShoppingCart']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["persist/PERSIST"]
            }
        }),
    devTools: !process.env.NEXT_PUBLIC_DISABLE_REDUX_DEV_TOOLS
});

const persistor = persistStore(store)

export {persistor, store};