import {combineReducers} from "@reduxjs/toolkit";

import {draftShoppingCartReducer} from "./draftShoppingCart.slice";
import {shoppingCartReducer} from "./shoppingCart.slice";

const rootReducer = combineReducers({
    draftShoppingCart: draftShoppingCartReducer,
    shoppingCart: shoppingCartReducer,
});

export default rootReducer