import {ChakraProvider} from '@chakra-ui/react';
import {NextIntlProvider} from "next-intl";
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react'

import Layout from '../components/sections/Layout';
import {persistor, store} from '../redux/store';
import theme from '../theme';

function MyApp({Component, pageProps}) {
    // There is an issue with redux-persist that prevents SSR from working correctly
    // https://stackoverflow.com/a/62404019
    return process.browser ? (

        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <NextIntlProvider messages={pageProps.messages}>
                    <ChakraProvider resetCSS theme={theme}>
                        <Layout>
                            <Component {...pageProps} />
                        </Layout>
                    </ChakraProvider>
                </NextIntlProvider>
            </PersistGate>
        </Provider>

    ) : (

        <Provider store={store}>
            <NextIntlProvider messages={pageProps.messages}>
                <ChakraProvider resetCSS theme={theme}>
                    <Layout>
                        <Component {...pageProps} />
                    </Layout>
                </ChakraProvider>
            </NextIntlProvider>
        </Provider>

    );
}

export default MyApp
